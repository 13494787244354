<template>
  <div>
    <div>
      <div class="text-label">Email Address</div>

      <v-text-field
        v-model="local.email"
        type="email"
        outlined
        @blur="showErrors.email = true"
        @focus="showErrors.email = false"
        :error-messages="
          showErrors.email && errorMessages.email ? errorMessages.email : null
        "
      ></v-text-field>
    </div>
    <div>
      <div class="text-label">Password</div>

      <v-text-field
        outlined
        v-model="local.password"
        type="password"
        @blur="showErrors.password = true"
        @focus="showErrors.password = false"
        :error-messages="
          showErrors.password && errorMessages.password
            ? errorMessages.password
            : null
        "
      ></v-text-field>
    </div>
    <div class="text-center">
      <v-btn v-bind="largeButton" @click="signIn" x-large :loading="processing">
        Sign In
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',
  data: function () {
    return {
      local: {
        email: '',
        password: ''
      },
      showErrors: {
        email: false,
        password: false
      },
      processing: false
    }
  },
  computed: {
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {
        email: null,
        password: null
      }

      if (this.local.email === '') {
        msgs.email = 'Required field'
      }

      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!msgs.email && !pattern.test(this.local.email)) {
        msgs.email = 'Not a valid email address'
      }

      if (this.local.password === '') {
        msgs.password = 'Required field'
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    localFormatted() {
      return {
        email: this.local.email.trim(),
        password: this.local.password
      }
    }
  },
  methods: {
    signIn() {
      this.showErrorsSwitch()
      if (this.isValid) {
        this.processing = true
        this.$store
          .dispatch('account/auth_login', this.localFormatted)
          .then(() => {
            this.$store.commit('app/SET_DATA_LOADED', false)
            this.$router.push({ path: '/' })
          })
          .catch((err) => {
            this.$store.commit('app/SET_MESSAGE', {
              text: err.message,
              type: 'error'
            })
            this.processing = false
          })
      }
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showError[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    }
  }
}
</script>

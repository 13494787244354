<template>
  <div>
    <v-alert
      type="error"
      v-if="error"
      tile
      colored-border
      border="left"
      class="grey lighten-5"
    >
      {{ error }}
    </v-alert>
    <div class="mb-4">
      <h3 class="mb-4">Email Address</h3>

      <v-text-field
        v-model="local.email"
        type="email"
        outlined
        @blur="showErrors.email = true"
        @focus="showErrors.email = false"
        :error-messages="
          showErrors.email && errorMessages.email ? errorMessages.email : null
        "
      ></v-text-field>
    </div>
    <v-btn v-bind="largeButton" @click="submit" x-large :loading="processing">
      Reset
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ResetPassword',
  data: function () {
    return {
      local: {
        email: ''
      },
      showErrors: {
        email: false
      },
      processing: false
    }
  },
  computed: {
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {
        email: null
      }

      if (this.local.email === '') {
        msgs.email = 'Required field'
      }

      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!msgs.email && !pattern.test(this.local.email)) {
        msgs.email = 'Not a valid email address'
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    error() {
      return this.$store.getters['app/app_message'].text
    },
    localFormatted() {
      return {
        email: this.local.email.trim()
      }
    }
  },
  methods: {
    submit() {
      this.showErrorsSwitch()
      if (this.isValid) {
        this.processing = true
        this.$store
          .dispatch('account/auth_reset_password', this.localFormatted)
          .then(() => {
            this.$store.commit('app/SET_MESSAGE', {
              text: 'Check your email inbox for password reset link',
              type: 'success'
            })
            this.processing = false
          })
          .catch((error) => {
            if (error.message === 'RESET_PASSWORD_EXCEED_LIMIT') {
              this.$store.commit('app/SET_MESSAGE', {
                text: 'Error, You have exceed the reset password limit!',
                type: 'error'
              })
            } else
              this.$store.commit('app/SET_MESSAGE', {
                text: 'Error, could not send the email for reset password',
                type: 'error'
              })
            this.processing = false
          })
      }
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showError[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    }
  }
}
</script>
